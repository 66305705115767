.btn-class {
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  backdrop-filter: blur(2.5px);
  overflow: hidden;
  box-shadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.10)";

}
.btn-class::after {
  content: "";
  position: absolute;
  bottom: 0px;
  inset: 70% 20% -230% 20%;
  border-radius: 50%;
  opacity: 0.4;
  background: #21c3e7;
  filter: blur(40px);
  transition: all 0.3s ease-out;
}


.btn-class:hover::after{
  inset: 30% 0% -230% 0%;
  filter: blur(20px);
}

.button-shadow-div {
  box-shadow: 0px 2px 5px -4px #00000045 inset;
  border-radius: 30px;
  border-bottom: 1px solid #21C3E7;
  transition: all ease-in-out .3s;
}
.button-shadow-div:hover{
  box-shadow: 0px -10px 10px -7px #21C3E7 inset;
}