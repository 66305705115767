/* .hero-video{
    background: linear-gradient(#ffffff00 10%, #21C3E7);
} */

.absolute-div {
	position: absolute;
	top: -26px;
	height: 74px;
	width: 100%;
	border-radius: 50%;
	filter: blur(30px);
	background: #21C3E7;
	z-index: -1;
	opacity: 0;
	transition: all 0.5s ease-out;
}
li:hover a{
	color: #21C3E7;
}
li:hover .absolute-div {
	opacity: 1;
}

.video-background iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;           
	transform: translate(-50%, -50%);
}
.comingsoon-inner {
	text-align: center;
	position: absolute;
	top: 65%;
	transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	right: 0;
	left: 0;
	margin: 0 auto;
	z-index: 3;
}
.comingsoon-inner .comingsoon-content p {
	letter-spacing: 1px;
	text-transform: capitalize;
	color: #ffffff;
}
@media (min-aspect-ratio: 16/9) {
    .video-background iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-background iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }